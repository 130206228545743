import React from "react";

import { Main, Footer } from "@components";

const Home: React.FC = () => {
    return (
        <div className="d-flex flex-column min-vh-100">
            <Main />
            <Footer />
        </div>
    );
};

export default Home;
