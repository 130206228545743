import React from "react";
import { Card as BaseCard, Popconfirm, Popover } from "antd";
import {
    DeleteOutlined,
    SearchOutlined,
    FileAddOutlined,
} from "@ant-design/icons";
import { CardProps } from "antd";

const { Meta } = BaseCard;
export interface ICard extends CardProps {
    description?: React.ReactNode;
    showDelete?: boolean;
    showScan?: boolean;
    showEdit?: boolean;
    showAddImage?: boolean;
    onClickDelete?: (id: string | undefined) => void;
    onClickScan?: () => void;
    onClickAddImage?: () => void;
}

export const Card: React.FC<ICard> = ({
    title,
    children,
    description,
    hoverable,
    showDelete,
    showScan,
    showAddImage,
    id,
    onClickDelete,
    onClickScan,
    onClickAddImage,
    ...rest
}) => {
    const actions = [];
    if (showDelete) {
        actions.push(
            <Popconfirm
                title="Are you sure you want to delete this?"
                okText="Yes"
                cancelText="No"
                onConfirm={(event) => {
                    event.stopPropagation();
                    onClickDelete(id);
                }}
            >
                <DeleteOutlined />
            </Popconfirm>,
        );
    }

    if (showScan) {
        const content = (
            <div>
                <p>Scan store for new items.</p>
                <p>
                    Note that it may take several minutes for your items to
                    appear in your wardrobe.
                </p>
                <p>
                    At the moment, initiating a scan takes a lot of memory, and
                    the server may very well start throwing errors for a while.
                    You may just want to click the button and go grab lunch.
                </p>
            </div>
        );
        actions.push(
            <Popover content={content}>
                <SearchOutlined onClick={onClickScan} />
            </Popover>,
        );
    }

    if (showAddImage) {
        const content = (
            <div>
                <p>Add Images</p>
            </div>
        );
        actions.push(
            <Popover content={content}>
                <FileAddOutlined onClick={onClickAddImage} />
            </Popover>,
        );
    }
    return (
        <BaseCard id={id} hoverable={hoverable} actions={actions} {...rest}>
            <Meta title={title} description={description} />
            {children}
        </BaseCard>
    );
};

Card.defaultProps = {
    hoverable: true,
    showDelete: true,
};
