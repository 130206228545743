import React from "react";

export const Footer: React.FC = () => {
    return (
        <div
            className="text-center py-4"
            style={{ backgroundColor: "#282c34" }}
        >
            <ul
                className="d-flex justify-content-center list-unstyled p-0 m-0"
                data-testid="icons-container"
            ></ul>
        </div>
    );
};
