import React from "react";

import { Button } from "@components";
import { Typography } from "antd";

const { Title } = Typography;

export const Main: React.FC = () => {
    return (
        <div
            style={{
                textAlign: "center",
                width: "100%",
                height: "100%",
                justifyContent: "center",
            }}
        >
            <Title>Caffeinated Wardrobe</Title>

            <p className="lead text-white">Catchy tagline here!</p>
            <Button
                type="primary"
                size="large"
                data-test="docs-btn-anchor"
                href="/stores"
            >
                Get Started
            </Button>
        </div>
    );
};
